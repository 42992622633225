const kSessionID: string = "kSessionID";

export default class CachingManger {
    private sessionId?: string

    public loadFromLocalStorage() {
        this.sessionId = localStorage.getItem(kSessionID) || undefined;
    }

    hasSession(): boolean {
        return this.sessionId !== undefined;
    }

    getSessionId(): string | undefined{
        return this.sessionId;
    }

    saveSessionId(sid: string) {
        this.sessionId = sid;
        localStorage.setItem(kSessionID, sid);
    }

    clearSessionId() {
        this.sessionId = undefined;
        localStorage.removeItem(kSessionID);
    }
}