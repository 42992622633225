import RestManager from "./REST/RestManager";
import SignedRestManager from "./REST/SignedRestManager";
import { RaveConfiguration } from "../RaveClient";

export default class BaseManager {
    restManager: RestManager
    signedRestManager: SignedRestManager
    raveConfig: RaveConfiguration
    constructor(rManager: RestManager, signedRestManager: SignedRestManager, configuration: RaveConfiguration) {
        this.restManager = rManager;
        this.signedRestManager = signedRestManager;
        this.raveConfig = configuration;
    }
};