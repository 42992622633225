import BasePlugin from "./BasePlugin";

export default class GooglePlugin extends BasePlugin {
    authCode: string

    constructor(appId: string, authCode: string) {
        super(appId);
        this.authCode = authCode;
    }

    public pathExtension?: string = "/google";

    public async authenticationPayload(): Promise<{}> {
        return {
            google: {
              auth_code: this.authCode,
            },
            app: {
              uuid: this.appId,
            },
            device: {
              identifier: "abcd1234",
              name: "Test Device",
            }
        };
    }
}