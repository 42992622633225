import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RaveUser } from '..';
import RaveClient from '../client/RaveClient';

export const CLIENT_API_URL = process.env.REACT_APP_CLIENT_API_URL || 'http://127.0.0.1:5000';
export const APPLICATION_UUID = process.env.REACT_APP_APPLICATION_UUID || '139adceff406467eb3ed8a9db9dd1d1a';


export interface RaveState {
    currentUser?: RaveUser,
    errorMessage?: string,
    authenticationStatus: 'loggedIn' | 'loggedOut' | 'unknown' | 'loggingIn' | 'loggedInAnonymous'
    status: 'initializing' | 'initialized'
    expectedWalletAddress?: string,
    sessionId?: string
};

export interface LoginState {
    status: 'logging_in' | 'finished'
}

const client = new RaveClient({baseUrl: CLIENT_API_URL, appId: APPLICATION_UUID});
const name = 'Rave';

const initialState: RaveState = {
    authenticationStatus: 'unknown',
    status: 'initializing'
};

export const raveSlice = createSlice({
    name,
    initialState,
    reducers: {
        logout: (state) => {
            if(state.status === 'initializing' || state.authenticationStatus === 'unknown' || state.authenticationStatus === 'loggedOut') {
                return;
            }
            client.logOut();
            state.currentUser = client.authenticationManager.currentUser;
            if(state.currentUser === undefined) {
                state.authenticationStatus = 'loggedOut';
            }
        },
        clearError: (state) => {
            state.errorMessage = undefined;
        },
        setExpectedWalletAddress: (state, action) => {
            state.expectedWalletAddress = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(resolveIdentity.pending, (state) => {
                state.status = 'initializing';
            })
            .addCase(resolveIdentity.fulfilled, (state, action) => {
                state.status = 'initialized';
                state.currentUser = action.payload;
                if(state.currentUser !== undefined) {
                    if(state.currentUser.accountState === 'anonymous' || state.currentUser.accountState === 'personalized') {
                        state.authenticationStatus = 'loggedInAnonymous'
                    } else {
                        state.authenticationStatus = 'loggedIn';
                    }
                } else {
                    state.authenticationStatus = 'loggedOut';
                }
            })
            .addCase(resolveIdentity.rejected, (state, action) => {
                window.alert(action.payload);
            })
            .addCase(disconnectEthereum.pending, (state) => {
            })
            .addCase(disconnectEthereum.fulfilled, (state, action) => {
                state.currentUser = action.payload;
                if(state.currentUser !== undefined) {
                    if(state.currentUser.accountState === 'anonymous' || state.currentUser.accountState === 'personalized') {
                        state.authenticationStatus = 'loggedInAnonymous'
                    } else {
                        state.authenticationStatus = 'loggedIn';
                    }
                } else {
                    state.authenticationStatus = 'loggedOut';
                }
            })
            .addCase(disconnectEthereum.rejected, (state, action) => {
                state.errorMessage = action.error.message;
            })
            .addCase(ConnectEthereum.pending, (state) => {
            })
            .addCase(ConnectEthereum.fulfilled, (state, action) => {
                state.currentUser = action.payload;
                if(state.currentUser !== undefined) {
                    if(state.currentUser.accountState === 'anonymous' || state.currentUser.accountState === 'personalized') {
                        state.authenticationStatus = 'loggedInAnonymous'
                    } else {
                        state.authenticationStatus = 'loggedIn';
                    }
                } else {
                    state.authenticationStatus = 'loggedOut';
                }
            })
            .addCase(ConnectEthereum.rejected, (state, action) => {
                state.errorMessage = action.error.message;
            })
            .addCase(loginGoogle.pending, (state) => {
                if(state.authenticationStatus === "loggedInAnonymous" || state.authenticationStatus === "loggedOut") {
                    state.authenticationStatus = 'loggingIn'
                }
            })
            .addCase(loginGoogle.fulfilled, (state, action) => {
                state.currentUser = action.payload;
                if(state.currentUser?.accountState === 'anonymous' || state.currentUser?.accountState === 'personalized') {
                    state.authenticationStatus = 'loggedInAnonymous'
                } else if (state.currentUser?.accountState === 'authenticated') {
                    state.authenticationStatus = 'loggedIn'
                } else {
                    state.authenticationStatus = 'loggedOut';
                }
            })
            .addCase(loginGoogle.rejected, (state, action) => {
                state.errorMessage = action.error.message;
            })
            .addCase(RegisterEthereum.pending, (state) => {
                if(state.authenticationStatus === "loggedInAnonymous" || state.authenticationStatus === "loggedOut") {
                    state.authenticationStatus = 'loggingIn'
                }            
            })
            .addCase(RegisterEthereum.fulfilled, (state, action) => {
                state.currentUser = action.payload;
                if(state.currentUser?.accountState === 'anonymous' || state.currentUser?.accountState === 'personalized') {
                    state.authenticationStatus = 'loggedInAnonymous'
                } else if (state.currentUser?.accountState === 'authenticated') {
                    state.authenticationStatus = 'loggedIn'
                } else {
                    state.authenticationStatus = 'loggedOut';
                }
            })
            .addCase(RegisterEthereum.rejected, (state, action) => {
                state.errorMessage = action.error.message;
            })
            .addCase(LoginEthereum.pending, (state) => {
                if(state.authenticationStatus === "loggedInAnonymous" || state.authenticationStatus === "loggedOut") {
                    state.authenticationStatus = 'loggingIn'
                }
            })
            .addCase(LoginEthereum.fulfilled, (state, action) => {
                state.currentUser = action.payload;
                if(state.currentUser?.accountState === 'anonymous' || state.currentUser?.accountState === 'personalized') {
                    state.authenticationStatus = 'loggedInAnonymous'
                } else if (state.currentUser?.accountState === 'authenticated') {
                    state.authenticationStatus = 'loggedIn'
                } else {
                    state.authenticationStatus = 'loggedOut';
                }
            })
            .addCase(LoginEthereum.rejected, (state, action) => {
                state.errorMessage = action.error.message;
            });
    },
});

export const { logout } = raveSlice.actions;

export const selectCurrentUser = (state: { rave:RaveState }) => state.rave.currentUser;

export const selectErrorMessage = (state: { rave: RaveState }) => state.rave.errorMessage;

export const selectAuthenticationState = (state: {rave: RaveState}) => state.rave.authenticationStatus;

export const resolveIdentity = createAsyncThunk<RaveUser | undefined>(
    'rave/resolveIdentity',
    async (_, { rejectWithValue }) => {
        try {
            await client.resolveIdentity();
            return client.authenticationManager.currentUser;
        } catch (error) {
            rejectWithValue(error);
        }
    }
)

export type LoginPayload = {
    authCode: string,
}

export const loginGoogle = createAsyncThunk<RaveUser | undefined, LoginPayload>(
    'rave/loginGoogle',
    async (payload: LoginPayload, { rejectWithValue }) => {
        try {
            await client.authenticationManager.loginWithGoogle(payload.authCode, );
            return client.authenticationManager.currentUser;
        } catch (error) {
            rejectWithValue(error);
        }
    }
)

export const RegisterEthereum = createAsyncThunk<RaveUser | undefined>(
    'rave/RegisterEthereum',
    async(payload, { rejectWithValue }) => {
        try {
            await client.authenticationManager.registerWithEthereum();
            return client.authenticationManager.currentUser;
        } catch (error) {
            rejectWithValue(error);
        }
    }
)
export const LoginEthereum = createAsyncThunk<RaveUser | undefined>(
    'rave/LoginEthereum',
    async(payload, { rejectWithValue }) => {
        try {
            await client.authenticationManager.loginWithEthereum();
            return client.authenticationManager.currentUser;
        } catch (error) {
            rejectWithValue(error);
        }
    }
)
export const ConnectEthereum = createAsyncThunk<RaveUser | undefined>(
    'rave/ConnectEthereum',
    async(payload, { rejectWithValue }) => {
        try {
            await client.authenticationManager.connectWithEthereum();
            return client.authenticationManager.currentUser;
        } catch (error) {
            rejectWithValue(error);
        }
    }
)

export type EthereumDisconnectPayload = {
    address: string,
    chain_id: number,
}

export const disconnectEthereum = createAsyncThunk<RaveUser | undefined, EthereumDisconnectPayload>(
    'rave/disconnectEthereum',
    async (payload: EthereumDisconnectPayload, { rejectWithValue }) => {
        try {
            await client.authenticationManager.disconnectEthereum(payload.address, payload.chain_id);
            return client.authenticationManager.currentUser;
        } catch (error) {
            rejectWithValue(error);
        }
    }
)

export default raveSlice.reducer;

