import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { RootState, AppDispatch } from './store';
import { ethers } from 'ethers';

export const CLIENT_API_URL = process.env.REACT_APP_CLIENT_API_URL || 'http://127.0.0.1:5000';


export type MessageSignature = {
    state: string,
    message: string,
    signature: string
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useSiwe = (): [boolean, () => void, boolean] => {
    const [isAvailable, setAvailable] = useState<boolean>(false);
    const [isAccountConnected, setAccountConnected] = useState<boolean>(false);
    // Check whether SIWE is available. 
    useEffect(() => {
      setAvailable(window.ethereum !== undefined);
      if(window.ethereum !== undefined) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        provider.send("eth_accounts", [])
          .then((accounts) => {
              setAccountConnected(accounts.length !== 0);
          })
          .catch(() => console.log("Unable to retrieve accounts"));
        return () => {};
      }
    }, []);

    const domain = window.location.host;
    const origin = window.location.origin;
    
    // Function to connect a wallet
    const connectWallet = (): void => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        provider.send('eth_requestAccounts', [])
                .then(() => setAccountConnected(true))
                .catch(() => console.log('User rejected request'));
    }

    return [isAvailable, connectWallet, isAccountConnected];
};