import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import raveReducer from '../ravesocial/redux/slice';

export const store = configureStore({
  reducer: {
    rave: raveReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
