import {MessageSignature} from "../../../../app/hooks";

export default class BasePlugin {
    public pathExtension?: string;
    protected appId: string

    constructor(appId: string) {
        this.appId = appId;
    }

    public async authenticationPayload(): Promise<{}> {
        throw new Error('Not implemented.');
    }

    public async registerPayload(): Promise<{}> {
        throw new Error('Not implemented.');
    }

    public disconnectPayload(): {} | undefined {
        return undefined;
    }
}