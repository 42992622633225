import React from 'react';

type SiweButtonProps = {
  callback: any
  text: string
}
  
export const SiweButton = (props: SiweButtonProps) => {
  return (
      <button onClick={props.callback} className='auth-button'>
        <img src="/ethereum-eth-logo.svg" alt="Ethereum logo"/>
        <span className="auth-button-text">{props.text}</span>
      </button>
  )
}
