import './App.css';
import { useAppDispatch, useAppSelector, useSiwe } from './app/hooks';

import { resolveIdentity, selectAuthenticationState, logout } from './ravesocial/redux/slice';
import { useEffect } from 'react';
import Profile from './app/Components/Profile';
import Login from './app/Components/Login';
import { SiweButton } from './app/Components/SiweButton';

function App() {
  const authenticationState = useAppSelector(selectAuthenticationState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resolveIdentity())
  }, [dispatch]);

  const body = authenticationState === 'loggedIn' ? <Profile /> : <Login />

  const [isAvailable, connectWallet, isAccountConnected] = useSiwe();

  return (
    <div className="App">
      <header className="App-header">
        { body }
        { authenticationState === 'loggedInAnonymous' ? <button onClick={() => dispatch(logout())}>Log out guest user</button> : null }
        <div className="text-white py-4">
          { isAvailable && !isAccountConnected ? <SiweButton callback={connectWallet} text="Connect Wallet Provider" /> : null }
        </div>
      </header>
      <div className="footer-copyright text-center py-3">
          &copy; {new Date().getFullYear()} Copyright: <a href="https://www.ravesocial.co"> Rave Social </a>
      </div>
    </div>
  );
}

export default App;
