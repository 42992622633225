import BasePlugin from "./BasePlugin";
import {ethers} from "ethers";
import {CLIENT_API_URL} from "../../../../app/hooks";

export default class EthereumPlugin extends BasePlugin {
    public pathExtension?: string = "/ethereum";
    private address?: string
    private chain_id?: number

    constructor(appId: string) {
        super(appId);
    }

    public setDisconnectParams(address: string, chain_id: number) {
      this.address = address;
      this.chain_id = chain_id;
    }

    private async getEthereumProvider() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const { chainId } = await provider.getNetwork();
        return { provider, signer, address, chainId };
    }

    private async fetchChallenge(apiEndpoint: string, address: string, chainId: number) {
        const payload = {
            "ethereum": {
                "address": address,
                "chain_id": chainId
            }
        };
        const response = await fetch(apiEndpoint, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {'Content-Type': 'application/json; charset=UTF-8'}
        });
        if (!response.ok) {
            const { errors } = await response.json();
            console.error("Error fetching challenge:", errors);
            throw new Error("Error fetching challenge.");
        }
        const { data } = await response.json();
        return { message: data.message, state: data.state };
    }


    public async registerPayload(): Promise<{}> {
        const { signer, address, chainId } = await this.getEthereumProvider();
        const challenge = await this.fetchChallenge(`${CLIENT_API_URL}/v5/auth/register/ethereum/challenge`, address, chainId);
        const signature = await signer.signMessage(challenge.message);

        return {
            ethereum: {
                state: challenge.state,
                message: challenge.message,
                signature: signature,
            },
            app: {
              uuid: this.appId,
            },
            device: {
              identifier: "abcd1234",
              name: "Test Device"
            }
          };
    }

    public async authenticationPayload(): Promise<{}> {
        const { signer, address, chainId } = await this.getEthereumProvider();
        const challenge = await this.fetchChallenge(`${CLIENT_API_URL}/v5/auth/sessions/ethereum/challenge`, address, chainId);
        const signature = await signer.signMessage(challenge.message);

        return {
            ethereum: {
                state: challenge.state,
                message: challenge.message,
                signature: signature,
            },
            app: {
              uuid: this.appId,
            },
            device: {
              identifier: "abcd1234",
              name: "Test Device"
            },
            force: true
        };
    }
    
    public disconnectPayload(): {} | undefined {
      if (this.address === undefined || this.chain_id === undefined) {
        return undefined;
      }
      return {
        ethereum: {
          address: this.address,
          chain_id: this.chain_id,
        }
      }
    }
}