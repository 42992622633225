import React, { MouseEvent } from 'react'
import { useAppDispatch, useSiwe } from '../hooks';
import { RegisterEthereum, loginGoogle, LoginPayload, LoginEthereum } from '../../ravesocial/redux/slice';
import { SiweButton } from './SiweButton';
import { GoogleButton } from './GoogleButton';

import {useGoogleLogin} from '@react-oauth/google';


// User is not logged in at all.
function Login() {
  const dispatch = useAppDispatch();

  const onLoginWithGoogle = useGoogleLogin({
    onSuccess: response => {
      if (response.code !== undefined) {
        const payload: LoginPayload = {
          authCode: response.code!,
        }
        dispatch(loginGoogle(payload));
      }
    },
    flow: 'auth-code',
    onError: errorResponse => {
      console.log(`Google login failure: ${errorResponse.error}`);
    }
  });

  const onRegisterWithEthereum = async (event: MouseEvent) => {
    event.preventDefault();
    dispatch(RegisterEthereum());
  };
  const onLoginWithEthereum = async (event: MouseEvent) => {
    event.preventDefault();
    dispatch(LoginEthereum());
  };

  const [isAvailable] = useSiwe();

  return (
      <div className="card login-card">
        <div className="login-header">
          <img className="rave-logo" src='/rave_logo.png' alt="Rave Logo"/>
        </div>
        <div className="login-body">
          <GoogleButton callback={onLoginWithGoogle} text="Sign in with Google"/>
          {isAvailable ? <SiweButton callback={onRegisterWithEthereum} text="Register with Ethereum"/> :
              <p className="login-unavailable">Register with Ethereum not available.</p>}
          {isAvailable ? <SiweButton callback={onLoginWithEthereum} text="Sign in with Ethereum"/> :
              <p className="login-unavailable">Sign in with Ethereum not available.</p>}
        </div>
      </div>
  )
}

export default Login;