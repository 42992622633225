export default class RestManager {
    baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async get<TResponse>(path: string, config: RequestInit = { method: 'GET'}): Promise<TResponse> {
        let loadedConfig = {...config, headers: this.buildHeaders() }
        return fetch(this.buildUrl(path), loadedConfig)
          .then((response) => response.json())
          .then((data) => data as TResponse);
    }

    public async post<TResponse>(path: string, payload: {}, config: RequestInit = { method: 'POST' }): Promise<TResponse> {
        let loadedConfig = {...config, body: JSON.stringify(payload), headers: this.buildHeaders() }
        return fetch(this.buildUrl(path), loadedConfig)
          .then((response) => response.json())
          .then((data) => data as TResponse);
    }

    public async put<TResponse>(path: string, payload: {}, config: RequestInit = { method: 'PUT' }): Promise<TResponse> {
        let loadedConfig = {...config, body: JSON.stringify(payload), headers: this.buildHeaders() }
        return fetch(this.buildUrl(path), loadedConfig)
          .then((response) => response.json())
          .then((data) => data as TResponse);
    }

    public async delete<TResponse>(path: string, payload?: {}, config: RequestInit = { method: 'DELETE'}): Promise<TResponse> {
        var loadedConfig = {...config, headers: this.buildHeaders() }
        if (payload !== undefined) {
            loadedConfig = { ...loadedConfig, body: JSON.stringify(payload!) }
        }
        return fetch(this.buildUrl(path), loadedConfig)
          .then((response) => response.json())
          .then((data) => data as TResponse);
    }

    protected buildHeaders(): {} {
        return {
            'Content-Type': 'application/json'
        }
    }

    private buildUrl(path: string): string {
        return `${this.baseUrl}/v5/${path}`;
    }
}