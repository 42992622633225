import CachingManger from "../CachingManager";
import RestManager from "./RestManager";

export default class SignedRestManager extends RestManager {
    cachingManager: CachingManger
    constructor(baseUrl: string, cachingManager: CachingManger) {
        super(baseUrl);
        this.cachingManager = cachingManager;
    }
    protected buildHeaders(): {} {
        if (!this.cachingManager.hasSession()) {
            throw new Error('Session does not exist for session.');
        }
        const headers = super.buildHeaders();
        return { ...headers, 'Sid': this.cachingManager.getSessionId(), 'Timestamp': Math.round(new Date().getTime() / 1000) }
    }
}