import { RaveConfiguration } from "../RaveClient";
import { RaveUser, RaveWallet } from "../..";
import BaseManager from "./BaseManager";
import CachingManger from "./CachingManager";
import RestManager from "./REST/RestManager";
import SignedRestManager from "./REST/SignedRestManager";
import BasePlugin from './AuthPlugins/BasePlugin';
import GooglePlugin from './AuthPlugins/Google';
import EmailPlugin from './AuthPlugins/Email';
import AnonymousPlugin from "./AuthPlugins/Anonymous";
import { generateHexString } from "../utilities/strings";
import EthereumPlugin from "./AuthPlugins/Ethereum";
import { ObjectFlags } from "typescript";


class APIError extends Error {
    public code: number;

    constructor(error: { code: number; message: string }) {
        super(error.message);
        this.code = error.code;
    }
}

type WalletInfo = {
    wallet_address: String
    chain_id: number
}

type RegistrationResponse = {
    data?: {
        google: {
            connected: boolean;
            id_token: string;
        },
        user: {
            uuid: string;
            display_name: string;
            profile_image_url?: string;
            state: "anonymous" | "personalized" | "authenticated";
            wallet_addresses: WalletInfo[];
        }
    },
    error?: {
        code: number;
        message: string;
    }
}

type AnonymousRegistrationResponse = {
    data?: {
        uuid: string;
        display_name: string;
        state: "anonymous" | "personalized" | "authenticated";
    },
    error?: {
        code: number;
        message: string;
    }
}

type MeResponse = {
    data?: {
        user: {
            display_name: string,
            uuid: string,
            profile_image_url?: string;
            state: "anonymous" | "personalized" | "authenticated"
            wallet_addresses: WalletInfo[];
        }
    },
    error?: {
        code: number;
        message: string;
    }
}

type DisconnectResponse = {
    data?: {
        user: {
            uuid: string,
            wallet_address?: string
        },
        ethereum: {
            connected: boolean
        }
    },
    error?: {
        code: number;
        message: string;
    }
}

type Session = {
    data?: {
        session:{
            sid: string
        }
        sid: string;
        user: object;
    },
    error?: {
        code: number;
        message: string;
    }
}

type ConnectResult = {
    result: "success" | "requires_merge"
}

type ConnectResponse = {
    error?: {
        code: number;
        message: string;
    }
}

export default class AuthenticationManager extends BaseManager {
    public currentUser?: RaveUser

    public sessionStore: CachingManger

    constructor(restManager: RestManager, signedRestManager: SignedRestManager, config: RaveConfiguration, sessionStore: CachingManger) {
        super(restManager, signedRestManager, config);
        this.sessionStore = sessionStore;
    }

    // TESTING METHODS

    public async loginWithStubUser() {
        this.sessionStore.saveSessionId("stubsessionid");
        await this.updateCurrentUser();
    }

    // END TESTING METHODS

    public async registerWithEthereum() {
        let plugin = new EthereumPlugin(this.raveConfig.appId);
        if(this.currentUser !== undefined) {
            throw new Error("User already authenticated.");
        } else {
            try {
                await this.pluginRegister(plugin);
                alert("Registration successful");
            } catch (e) {
                window.alert(e.message);
            }
        }
    }
    public async loginWithEthereum() {
        let plugin = new EthereumPlugin( this.raveConfig.appId);
        if(this.currentUser !== undefined) {
            await this.connectWithPlugin(plugin);
        } else {
            await this.loginWithPlugin(plugin);
        }
    }
    public async connectWithEthereum() {
        let plugin = new EthereumPlugin(this.raveConfig.appId);
        if(this.currentUser !== undefined) {
            await this.connectWithPlugin(plugin);
        } else {
            await this.loginWithPlugin(plugin);
        }
    }

    public async loginWithGoogle(authCode: string) {
        let plugin = new GooglePlugin(this.raveConfig.appId, authCode);
        if(this.currentUser !== undefined) {
            await this.connectWithPlugin(plugin);
        } else {
            await this.loginWithPlugin(plugin);
        }
    }

    public async loginWithEmailAndPassword(email: string, password: string) {
        let plugin = new EmailPlugin(email, password, this.raveConfig.appId);
        await this.loginWithPlugin(plugin);
    }

    public async registerGuestUser() {
        let uuid = generateHexString(32);
        let plugin = new AnonymousPlugin(uuid, this.raveConfig.appId);
        await this.loginWithPlugin(plugin);
    }

    public async connectGoogle(authCode: string) {
        let plugin = new GooglePlugin(this.raveConfig.appId, authCode);
        await this.connectWithPlugin(plugin);
    }

    public async connectEthereum(state: string, message: string, signature: string, chainId: number, address: string, domain: string) {
        let plugin = new EthereumPlugin(this.raveConfig.appId);
        await this.connectWithPlugin(plugin);
    }

    public async disconnectEthereum(address: string, chain_id: number) {
        let plugin = new EthereumPlugin(this.raveConfig.appId)
        plugin.setDisconnectParams(address, chain_id);
        await this.disconnectPlugin(plugin);
    }

    private async connectWithPlugin(plugin: BasePlugin) {
        try {
            let result = await this.performConnect(plugin);
            if(result.result === "requires_merge") {
                await this.mergePlugin(plugin);
            }
            // Maybe this should login instead after merging? As the session will be invalidated
            await this.updateCurrentUser();
        } catch (e) {
            window.alert(e);
        }
    }

    private async mergePlugin(plugin: BasePlugin) {
        let payload = await plugin.authenticationPayload();
        let result = await this.signedRestManager.post<MeResponse>("me/merge", payload);
        if(result.error !== undefined) {
            console.log(result.error!);
            throw new Error('Merging user: ' + result.error!.message);
        }
    }

    private async performConnect(plugin: BasePlugin): Promise<ConnectResult> {
        let payload = await plugin.authenticationPayload();
        let path = `me${plugin.pathExtension}`
        let response = await this.signedRestManager.post<ConnectResponse>(path, payload);
        if (response.error !== undefined) {
            if(response.error!.code === 440 || response.error!.code === 442 || response.error!.code === 443) {
                alert(response.error!.message);
                return {
                    result: "requires_merge"
                }
            } else {
                throw new Error('Failed to connect: ' + response.error!.message);
            }
        }
        return {
            result: 'success'
        }
    }

    private async loginWithPlugin(plugin: BasePlugin) {
        try {
            // Attempt to get a session.
            let session = await this.pluginLogin(plugin);
            this.sessionStore.saveSessionId(session);

            // Update the current user now that we have a session.
            await this.updateCurrentUser();
        } catch (e) {
            console.log("error logging in: " + e.message);
            if(e.code === 401) {
                alert(e + ". Falling back to register...");
                // Need to register first
                try {
                    let user = await this.pluginRegister(plugin);
                    let session = await this.pluginLogin(plugin);
                    this.sessionStore.saveSessionId(session);
                    this.currentUser = user;
                } catch (error) {
                    // An error was encountered during the authentication process.
                    throw new Error("Unable to login")
                }
            }
        }
    }

    public async updateCurrentUser(): Promise<void> {
        const response = await this.signedRestManager.get<MeResponse>('me');
        if(response.error !== undefined) {
            throw new APIError(response.error);
        }

        const wallets: RaveWallet[] = response.data!.user.wallet_addresses.map((info) => {
            return {
                address: info.wallet_address,
                chain_id: info.chain_id,
            } as RaveWallet
        });
        let sessionId = this.sessionStore.getSessionId()
        const user: RaveUser = {
            displayName: response.data!.user.display_name,
            uuid: response.data!.user.uuid,
            profilePictureUrl: response.data!.user.profile_image_url,
            accountState: response.data!.user.state,
            wallets,
            sessionId
            
        }
        this.currentUser = user;
    }

    private async pluginRegisterAnonymous(plugin: BasePlugin): Promise<RaveUser> {
        const payload = await plugin.registerPayload();
        const path = `auth/register`
        const response = await this.restManager.post<AnonymousRegistrationResponse>(path, payload);
        if(response.error !== undefined) {
            throw new APIError(response.error);
        }
        let sessionId = this.sessionStore.getSessionId()

        return {
            uuid: response.data!.uuid,
            displayName: response.data!.display_name,
            accountState: response.data!.state,
            wallets: [],
            sessionId
        }
    }

    private async pluginLogin(plugin: BasePlugin): Promise<string> {
        let payload = await plugin.authenticationPayload();
        const path = `auth/sessions${plugin.pathExtension!}`
        const response = await this.restManager.post<Session>(path, payload);
        if(response.error !== undefined) {
            throw new APIError(response.error);
        }
        return response.data!.sid || response.data!.session.sid;
    }

    private async pluginRegister(plugin: BasePlugin): Promise<RaveUser> {
        // We have a different return value of the registration response apparently for anonymous users.
        if (plugin instanceof AnonymousPlugin) {
            return this.pluginRegisterAnonymous(plugin);
        }
        const payload = await plugin.registerPayload();
        const path = `auth/register${plugin.pathExtension!}`
        const response = await this.restManager.post<RegistrationResponse>(path, payload);
        if(response.error !== undefined) {
            throw new APIError(response.error);
        }
        
        const wallets: RaveWallet[] = response.data!.user.wallet_addresses.map((info) => {
            return {
                address: info.wallet_address,
                chain_id: info.chain_id,
            } as RaveWallet
        });
        let sessionId = this.sessionStore.getSessionId()

        return {
            uuid: response.data!.user.uuid,
            displayName: response.data!.user.display_name,
            profilePictureUrl: response.data!.user.profile_image_url,
            accountState: response.data!.user.state,
            wallets,
            sessionId

        }
    }

    private async disconnectPlugin(plugin: BasePlugin): Promise<void> {
        const path = `me${plugin.pathExtension!}`
        const response = await this.signedRestManager.delete<DisconnectResponse>(path, plugin.disconnectPayload())
        if(response.error !== undefined) {
            throw new APIError(response.error);
        }
        console.log("updating")
        await this.updateCurrentUser()
        console.log("update complete")
    }
}