import React from 'react'
import { useAppDispatch, useAppSelector, useSiwe } from '../hooks';
import { ConnectEthereum, logout, selectCurrentUser } from '../../ravesocial/redux/slice';
import Wallet from './Wallet';

function Profile() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser);

  const onLogout = (event: any) => {
    event.preventDefault();
    dispatch(logout());
  };

  const connectCurrentWallet = async (event: any) => {
    event.preventDefault();
    dispatch(ConnectEthereum()); // If user is already authenticated it should call me/ethereum
  }

  if (!user) {
    return (
        <div className="error-message">
          <p>Error loading user from store.</p>
        </div>
    );
  }

  return (
    <div className="justify-center items-center w-auto">
    <div className="card">
    <div className="px-6 py-4 shadow-inner content-center justify-center flex">
      <img className="rounded-full max-h-20" src={user.profilePictureUrl !== null ? user.profilePictureUrl : "/empty_profile.png"} alt="Profile" />
    </div>
      <div className="px-6 pt-2 pb-2">
        <p>Account Profile</p>
        <hr/>
      </div>
      <div className="px-6 py-2">
        <p className="text-sm"><b>Rave ID:</b> { user.uuid }</p>
        <p className="text-sm"><b>Display Name:</b> { user.displayName }</p>

      <button className="profile-button logout-button" onClick={onLogout}>Logout</button>
    </div>
  </div>
  <div className="card mt-5">
    <div className="px-6 py-4 shadow-inner">
      <h4>Connected Wallets</h4>
      <hr />
      { user.wallets.map((wallet, index) => <Wallet key={index} address={wallet.address} chain_id={wallet.chain_id}/>)}
      <button className="profile-button" onClick={connectCurrentWallet}>Connect Current Wallet</button>
      <p className="text-sm mt-6"><b>Session Id:</b> { user.sessionId }</p>
    </div>
  </div>
  </div>
    )
}

export default Profile