import React from 'react';
import { disconnectEthereum, EthereumDisconnectPayload } from '../../ravesocial/redux/slice';
import { useAppDispatch } from '../hooks';

export type WalletProps = {
    address: string;
    chain_id: number;
};

const Wallet: React.FC<WalletProps> = ({ address, chain_id }) => {
    const dispatch = useAppDispatch();
    const disconnect = () => {
        const payload: EthereumDisconnectPayload = {
            address,
            chain_id,
        }
        dispatch(disconnectEthereum(payload));
    }

    return (
        <div className="flex justify-center">
            <span className="text-sm pt-2 h-1 m-2">{address}</span>
            <span><button className="profile-button" onClick={disconnect}>Disconnect</button></span>
        </div>
    )
};

export default Wallet;