import AuthenticationManager from "./managers/AuthenticationManager";
import CachingManger from "./managers/CachingManager";
import RestManager from "./managers/REST/RestManager";
import SignedRestManager from "./managers/REST/SignedRestManager";

export type RaveConfiguration = {
    appId: string
}

export default class RaveClient {
    public authenticationManager: AuthenticationManager
    private cachingManager: CachingManger;

    // TODO: Make this some sort of setting.
    private shouldGenerateGuestUser: boolean = false;

    public constructor(config: {baseUrl: string, appId: string }) {
        let cachingManager = new CachingManger();
        this.cachingManager = cachingManager;
        this.cachingManager.loadFromLocalStorage();

        let configuration: RaveConfiguration = {
            appId: config.appId
        }

        let restManager = new RestManager(config.baseUrl);
        let signedRestManager = new SignedRestManager(config.baseUrl, cachingManager);

        this.authenticationManager = new AuthenticationManager(restManager, signedRestManager, configuration, cachingManager);
    }

    public async resolveIdentity() {
        if(this.cachingManager.hasSession()) {
            await this.authenticationManager.updateCurrentUser()
        } else {
            if(this.shouldGenerateGuestUser) {
                // Generate an anonymous user.
                await this.authenticationManager.registerGuestUser();
                await this.authenticationManager.updateCurrentUser();
            }
        }
    }

    public async logOut() {
        this.cachingManager.clearSessionId();
        this.authenticationManager.currentUser = undefined;
        await this.resolveIdentity();
    }
};