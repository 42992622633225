import BasePlugin from "./BasePlugin";

export default class GooglePlugin extends BasePlugin {
    email: string
    password: string

    public pathExtension?: string = "/password";

    constructor(email: string, password: string, appId: string) {
        super(appId);
        this.email = email;
        this.password = password;
    }

    public async registerPayload(): Promise<{}> {
        return {
            user: {
              email: this.email,
              password: this.password
            },
            app: {
              uuid: this.appId,
            },
            device: {
              identifier: "abcd1234",
              name: "Test Device"
            }
          };
    }

    public async authenticationPayload(): Promise<{}> {
        return {
            user: {
              email: this.email,
              password: this.password
            },
            app: {
              uuid: this.appId,
            },
            device: {
              identifier: "abcd1234",
            }
        };
    }
}