import BasePlugin from "./BasePlugin";

export default class AnonymousPlugin extends BasePlugin {
    public pathExtension?: string = "";
    private uuid: string

    constructor(uuid: string, appId: string) {
        super(appId);
        this.uuid = uuid;
    }

    public async registerPayload(): Promise<{}> {
        return {
            user: {
              uuid: this.uuid,
            },
            app: {
              uuid: this.appId,
            },
            device: {
              identifier: "abcd1234",
              name: "Test Device"
            }
          };
    }

    public async authenticationPayload(): Promise<{}> {
        return {
            user: {
              uuid: this.uuid,
            },
            app: {
              uuid: this.appId,
            },
            device: {
              identifier: "abcd1234",
              name: "Test Device"
            }
        };
    }
}